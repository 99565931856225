import React from "react";
import { graphql } from "gatsby";
import MDXRenderer from "gatsby-mdx/mdx-renderer";
import { Layout } from "../components/Layout";
import { Seo } from "../components/Seo";
import SimplePage from "../components/SimplePage";

class SimplePageTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx;

    const imgs = this.props.data.mdx.frontmatter.images;

    return (
      <Layout>
        <Seo
          title={post.frontmatter.title}
          description={
            post.frontmatter.seoDescription || "Amazing site by Jakub Joras"
          }
        />
        <main>
          <SimplePage>
            <MDXRenderer
              images={imgs}
              testowyProp={this.props.data.site.siteMetadata.title}
            >
              {post.code.body}
            </MDXRenderer>
          </SimplePage>
        </main>
      </Layout>
    );
  }
}

export default SimplePageTemplate;

export const pageQuery = graphql`
  query MdxPageBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      code {
        body
      }
      frontmatter {
        title
        seoDescription
        images {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  }
`;
