import React from "react";
import PropTypes from "prop-types";

import style from "../styles/post.module.css";

const SimplePage = ({ html, children }) => {
  return (
    <div className={style.post}>
      <div className={style.postContent}>
        {
          <>
            {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
            {children && <div> {children} </div>}
          </>
        }
      </div>
    </div>
  );
};

SimplePage.propTypes = {
  path: PropTypes.string,
  html: PropTypes.string,
};

export default SimplePage;
